@charset "utf-8"; @font-face{font-family:basicFont; src:url(https://dev.instaglobaltravel.com/assets/fonts/basefont.otf); font-display:swap;}
@font-face{font-family:titleFont; src:url(https://www.instaglobaltravel.com/assets/fonts/titleFont.ttf); font-display:swap;}
body{margin:0px; padding:0px; font-family:basicFont; font-size:14px; color:#000; overflow-y:scroll; font-weight:normal; line-height:22px; }
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, div{margin:0px; padding:0px; float:left; box-sizing:border-box; }
h1, h2, h3, h4, h5, h6, ul, ol, li, a, span, p, input{margin:0px; padding:0px; float:left; box-sizing:border-box; }
a{text-decoration:none; box-sizing:border-box; }
h1{font-size:30px; }
h2{font-size:24px; }
h3{font-size:20px; }
h4{font-size:16px; }
h5{font-size:14px; }
h6{font-size:13px; }
img{display:table; margin:0 auto; box-sizing:border-box; }
.lineheight{line-height:20px !important; }
.lineheightExtra{line-height:24px; }
.clear{clear:both; }
.borderNone{border:none!important; }
.borderRadius{border-radius:10px; }
.boxsizing{box-sizing:border-box; }
.fontBold{font-weight:bold; }
.fontweightNone{font-weight:normal; }
.floatNone{float:none; }
.posRelative{position:relative; }
.minWidth100{width:100px!important}
.menuIcon{display:none; }
.closeIcon{display:none; }
.displayBlock{display:block; }
.overflowDot{overflow:hidden; white-space:nowrap; text-overflow:ellipsis; }
.upperCase{text-transform:uppercase; }
.opty80{opacity:0.8; }
.seprater{background:#333; border-bottom:solid 1px #444; height:2px; width:100%; }
.mobGap{display:none; }
.img100Percent img{width:100%; height:100%; }
.titleFont{font-family:titleFont; }
.subTitleFont{font-family:subTitleFont; }
.font11{font-size:11px; line-height:15px; }
.font12{font-size:12px; line-height:18px; }
.font13{font-size:13px; line-height:18px; }
.font16{font-size:16px; line-height:22px; }
.font20{font-size:20px; line-height:26px; }
.font28{font-size:28px; line-height:34px; }
.font30{font-size:30px; line-height:36px; }
.font36{font-size:36px; line-height:42px; }
.widthAuto{width:auto; }
.cursorPointer{cursor:pointer; }
.resizeNone{resize:none; }
.gapping{padding:25px 0px; }
.gapping1{padding:30px 0px; }
.boxShadow {box-shadow:3px 3px 3px #ccc; }
.textShadow {text-shadow:3px 3px 3px #333; }

/*error or success class*/
.alertBox{width:100%; padding:10px; box-sizing:border-box; float:left; margin:0px 0px 10px; line-height:20px; border-radius:5px; text-align:center; }
.error{background-color:#f9dbdb!important; color:#d53e35!important; border:solid 1px #d53e35!important; padding:6px 10px; margin-bottom:10px; }
.success{background-color:#dff0d8!important; color:#3c763d!important; border:solid 1px #bbd4a6!important; padding:6px 10px; margin-bottom:10px; }
.notification{background-color:#d9edf7!important; color:#31708f!important; border:solid 1px #bce8f1!important; }
.warning{background-color:#fbf4d6!important; color:#86721d!important; border:solid 1px #e9ddac!important; }
.invalid-feedback::before {/* content:""; */ position:absolute; width:0;height:0;border-left:10px solid transparent;border-right:10px solid transparent;border-bottom:10px solid #d04f4e;margin-top:-13px; /*margin-left:16px; */right:390px; }
.invalid-feedback{/* background-color:#d04f4e !important;color:#fff !important; */color:#d04f4e !important; /* border:solid 1px #ad3938 !important; */padding:3px 3px;border-radius:5px;margin-bottom:10px;font-size:12px; }

/*border*/
.borderTop{border-top:solid 1px #ddd; }
.borderBottom{border-bottom:solid 1px #ddd; }
.borderRight{border-right:solid 1px #ddd; }
.borderLeft{border-left:solid 1px #ddd; }
.borderDashed{border:1px dashed #999999!important; }
.borderAll{border:solid 1px #ddd; }
.borderOrange{border-color:#774f21; }
.borderYellow{border-color:#ffbb6c; }
.borderRightWidth{border-Right-width:10px; }
.pull_left{float:left!important; }
.pull_right{float:right!important; }

/*align Text*/
.alignRight{text-align:right; }
.alignCenter{text-align:center; }
.alignLeft{text-align:left; }
.alignJustify{text-align:justify; }

/*margin*/
.marginTopsm{margin-top:5px; }
.marginBottomsm{margin-bottom:5px; }
.marginRight{margin-right:10px; }
.marginLeft{margin-left:10px; }
.marginTop{margin-top:10px; }
.marginBottom{margin-bottom:10px; }
.marginNone{margin:0px!important; }
.marginLeftNone{margin-left:0px!important; }
.marginRightNone{margin-right:0px!important; }
.marginTopNone{margin-top:0px!important; }
.marginBottomNone{margin-bottom:0px!important; }
.marginTop80{margin-top:80px !important; }
.marginTop40{margin-top:85px !important; }
.marginInBox{margin:10px; }

/*padding*/
.paddingRight{padding-right:10px; }
.paddingLeft{padding-left:10px; }
.paddingTop{padding-top:10px; }
.paddingBottom{padding-bottom:10px; }
.paddingNone{padding:0px!important; }
.paddingLeftNone{padding-left:0px!important; }
.paddingRightNone{padding-right:0px!important; }
.paddingTopNone{padding-top:0px!important; }
.paddingBottomNone{padding-bottom:0px!important; }

/*In Box*/
.paddingInBox{padding:10px; }
.paddingInBoxExtra{padding:20px; }
.paddingupdwn{padding-top:10px; padding-bottom:10px; }
.paddinglftrgt{padding-left:10px; padding-right:10px; }
.padding-sm-updwn{padding:5px 0px; }
.padding-sm{padding:5px; }
.padding-sm-lftrgt{padding:0px 5px; }
.padding-lg-lftrgt{padding:30px; }


/*rounded Corners*/
.roundCorner{border-radius:5px; }
.roundCornerExtra{border-radius:10px; }
.radius-1-0-0-1{border-radius:10px 0px 0px 10px; }
.radius-0-1-1-0{border-radius:0px 10px 10px 0px; }
.radius-0-0-1-1{border-radius:0px 0px 10px 10px; }
.radius-1-1-0-0{border-radius:10px 10px 0px 0px; }
.circle{border-radius:100%; }

/*btn css*/
.btn-xl{border-radius:5px; padding:15px 30px; cursor:pointer; border:none; font-family:basicFont; font-size:20px; margin-bottom:10px; font-weight:normal; box-sizing:border-box; }
.btn-lg{border-radius:5px; padding:10px 15px; cursor:pointer; border:none; font-family:basicFont; font-size:17px; margin-bottom:10px; font-weight:normal; box-sizing:border-box; }
.btn{border-radius:5px; padding:5px 10px; cursor:pointer; border:none; font-family:basicFont; font-size:14px; margin-bottom:10px; font-weight:normal; box-sizing:border-box; }
.btn-sm{border-radius:5px; padding:2px 5px; border:none; font-family:basicFont; font-size:12px; margin-bottom:10px; font-weight:normal; box-sizing:border-box; }
.primary-btn{background:#63bc29;background:-moz-linear-gradient(top,#63bc29 1%,#04903a 100%);background:-webkit-linear-gradient(top,#63bc29 1%,#04903a 100%);background:linear-gradient(to bottom,#63bc29 1%,#04903a 100%);color:#fff}
.primary-btn:hover{background:#04903a;background:-moz-linear-gradient(top,#04903a 0,#63bc29 99%);background:-webkit-linear-gradient(top,#04903a 0,#63bc29 99%);background:linear-gradient(to bottom,#04903a 0,#63bc29 99%);color:#fff}
.secondry-btn{background:transparent; color:#f69321; border:solid 1px #f69321; }
.secondry-btn:hover{background-color:#f69321; color:#fff; }
.custom-btn{background:#f69321; color:#fff; }
.custom-btn:hover{background-color:#fa8503; color:#fff; }
.custom1-btn{background:#510808; color:#fff; }
.custom1-btn:hover{background-color:#f69321; color:#510808; }
.custom2-btn {background:#510808;color:#fff; }
.custom2-btn:hover {background:#fff;color:#510808; }
.btn + .btn{margin-left:10px; }
.btn-block{display:block; width:100%; }
.login-fb{background:#3c5899; border:none; }
.login-fb:hover{background:#4f6bac; border:none; }
.login-google{background:#e24b39; border:none; }
.login-google:hover{background:#d96557; border:none; }

/*Font colors*/
.white{color:#fff; }
.black{color:#000; }
.grey{color:#333; }
.red{color:#df0c0c; }
.darkred{color:#510808; }
.blue{color:#1c88c8; }
.orange{color:#f69321; }
.green{color:#00991F; }
.brown{color:#510808; }
.yellow{color:#ffbb6c; }
.baseColor{color:#666; }
.orangeBG{background:#f4a244; }/*#f69321*/
.baseBG{background:#fefefe; }
.whiteBG{background:#fff; }
.blackBG{background:#000; }
.greyBG{background:#fefefe; }/*#f1f1f1*/
.greyBaseBG{background:#eee; }
.blueBG{background:#0AB5E7; }
.lightblueBG{background:#738286; }
.redBG{background:#FF5959; }
.greenBG{background:#46b975; }
.darkBlueBG{background:#23424b; }
/*.whiteBG-trans{background:#fff; opacity:0.5; }*/
.login-bg{background:url("https://www.instaglobaltravel.com/assets/images/yellow-bg.jpg") no-repeat Bottom center; height:335px; }

/*link*/
.links{color:#000; font-size:14px; padding:8px 0px; box-sizing:border-box; width:50%; }
.links:hover{color:#000; }

/*form-controls*/
.label{line-height:32px; }
.form-control{width:100%; padding:10px; box-sizing:border-box; border:none; margin-bottom:10px; font-family:basicFont; font-size:13px; line-height:20px; }
.form-control-sm{width:100%; padding:5px 10px; box-sizing:border-box; border:none; margin-bottom:10px; font-family:basicFont; font-size:13px; line-height:20px; }
.form-control-input{width:100%; padding:10px 10px; box-sizing:border-box; border:solid 1px #ddd; margin-bottom:10px; font-family:basicFont; font-size:13px; line-height:20px; background:#f3f3f3; border-radius:5px; outline:none; }
.selectWrap{overflow:hidden; }
.user, .users, .call, .mail, .user, .password {background-position:98% center; background-repeat:no-repeat; }
.user{background-image:url("https://www.instaglobaltravel.com/assets/images/user.png"); }
.password{background-image:url("https://www.instaglobaltravel.com/assets/images/password.png"); }
.mail{background-image:url("https://www.instaglobaltravel.com/assets/images/email.png"); }
.users{background-image:url("https://www.instaglobaltravel.com/assets/images/users.png"); }

/*width Definations*/
.col-sm-1{width:10%; min-height:1px; }
.col-sm-2{width:20%; min-height:1px; }
.col-sm-3{width:30%; min-height:1px; }
.col-sm-4{width:40%; min-height:1px; }
.col-sm-5{width:50%; min-height:1px; }
.col-sm-6{width:60%; min-height:1px; }
.col-sm-7{width:70%; min-height:1px; }
.col-sm-8{width:80%; min-height:1px; }
.col-sm-9{width:90%; min-height:1px; }
.col-sm-1Forth{width:25%; min-height:1px; }
.col-sm-1Third{width:33.33%; min-height:1px; }
.col-sm-2Third{width:66.66%; min-height:1px; }
.col-sm-3Forth{width:75%; min-height:1px; }

/*containers*/
.wrapper{width:100%; }
.row{width:100%; }
.container{margin:0 auto; float:none; display:table; }
.wrap{width:1200px; margin:0px auto; display:table; float:none; max-width:95%;}
.content{line-height:22px; text-align:justify; font-size:14px; }
.content p{margin:0px 0px 20px; }
.content ul{list-style:none; margin:0px 0px 10px; padding:0px; width:100%; }
.content ul li{width:100%; padding:3px 0px; }
.content ol{list-style:outside; margin:0px 0px 10px; padding:0px; width:100%; }
.content ol li{width:98%; padding:3px 0px; margin:0px 0px 10px 2%; }
.content1{line-height:22px; text-align:justify; font-size:14px; }
.content1 p{margin:0px 0px 20px; }
.content1 ul{list-style:outside; margin:0px 0px 3px; padding:0px; width:100%; }
.content1 ul li{width:98%; padding:0px 0px; margin:0px 0px 6px 2%; }

/*theme styles*/
.headerSection{padding:10px 0px; position:fixed; left:0px; top:0px; width:100%; background:#fff; z-index:1000;border-bottom:1px solid #eee; }
.logo-panel{position:relative; }
.logo-panel img { width:240px;}
/*.logo-panel:after{content:""; position:absolute; left:60px; bottom:-70px; border-style:solid; border-width:30px 50px; border-color:rgba(255,255,255,0.95) transparent transparent transparent; }*/
.navigation-panel{/*float:right; */}
.navigation-panel a{padding:10px 15px; color:#6c6c6c; font-size:14px; margin:10px 0px; /*text-align:center; */ margin-right:5px; cursor:pointer; }
.navigation-panel a:hover, .navigation-panel a.active{background:#f69321; color:#fff; border-radius:6px; }
.banner-panel{/*min-height:450px;*/ width:100%; padding-top:83px; position:relative; z-index:2; }
.excel{padding:12px 10px; border-radius:10px; font-size:16px; }
.soicalLinks {margin-top:0px; }
.soicalLinks a{color:#fff; padding:5px; border-radius:100%; background:#5c3d3d; margin-left:7px; width:24px; line-height:12px; height:24px; text-align:center; }
.soicalLinks-2x {margin-top:0px; }
.soicalLinks-2x a{color:#fff; padding:5px; border-radius:100%; background:#5c3d3d; margin-left:7px; width:48px; line-height:46px; height:48px; text-align:center; }
.height209{height:180px; }
.bodypanel{margin-top:0px; }
.footerSection{background:#fbfbfb; border-top:1px solid #eee; }
.blogListing{width:42%; margin:10px 2% 30px 6%; border:dashed 1px #fcbf79; padding:0px; position:relative; background:#e9e9ce; background:-moz-linear-gradient(top,  #e9e9ce 0%, #fcfff4 100%); background:-webkit-linear-gradient(top,  #e9e9ce 0%,#fcfff4 100%); background:linear-gradient(to bottom,  #e9e9ce 0%,#fcfff4 100%); filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9e9ce', endColorstr='#fcfff4',GradientType=0 ); height:280px; }
.blogListing:before{content:""; position:absolute; left:-1.5%; bottom:-10px; border:solid 1px #5c3d3d; background:#5c3d3d; width:20%; height:20%; z-index:-1; }
.blogListing:after{content:""; position:absolute; left:51.5%; top:-10px; border:solid 1px #5c3d3d; background:#5c3d3d; width:50%; height:50%; z-index:-1; }

/***** Blog details Pramod Kumar Start ********/
/*Start Blog*/
#sidebar {padding-left:30px; }
.widget {margin-bottom:30px; padding-bottom:30px; padding-left:10px; }
.widget ul {list-style:none; margin:0; padding:0; }
.widget_category li {padding:4px 20px; margin-bottom:8px; background:#111; }
.widget_category li a {color:#606060; }
.widget li {padding:8px 20px 8px 20px; margin-bottom:8px; border-bottom:1px solid #ccc; width:100%; }
.widget li a{padding:0px 10px; margin-bottom:8px; color:#111; }
.widget-post .date {background:#f69321; color:#fff; display:inline-block; padding:0px 5px 0px 5px; float:left; font-size:12px; }
.post-text {padding:30px 0px; }
.post-text blockquote{font-size:24px; font-style:normal; font-weight:500; line-height:1.4em; border:none; border-left:solid 3px #333333; padding-left:30px; color:#111111; border-left-color:#f69321; margin-top:110px; margin-left:0px; }
.blog-read img {margin-bottom:20px; max-width:100%; max-height:100%; }
.img-fullwidth {width:100%; height:auto; }
/*End Blog*/
/***** Blog details Pramod Kumarn end********/

.blogImg{position:absolute; left:-10%; top:30px; width:50%; }
.blogImg img{height:170px; border-radius:20px; box-shadow:10px 10px 0px 0px #fcbf79; }
.blogTitle{font-size:18px; padding:10px 0px; width:100%; margin:0px 0px 0px 0px; color:#000; border-bottom:solid 1px #fcbf79; }
.blogLeft{width:60%; float:right; padding:20px; }
.themeBG{background:url("https://www.instaglobaltravel.com/assets/images/bg.jpg") center center fixed no-repeat; position:relative; }
.themeBG:after{content:""; position:absolute; left:0px; top:0px; bottom:0px; right:0px; background:rgba(0,0,0,0.8); width:100%; height:100%; }
.upper{position:relative; z-index:1; }
.bg-login{background:url("https://www.instaglobaltravel.com/assets/images/bg-login.jpg") center center fixed no-repeat; position:relative; }
.fav{position:absolute; right:50px; font-size:16px !important; color:#fff; padding:15px; background:#5c3d3d; border-radius:0 10px 10px 0; width:19px; text-align:center; }
.leftMenu a{padding:10px; width:100%; border-bottom:solid 2px #ccc; color:#000; }
.leftMenu a:hover{border-bottom:dotted 2px #f69321; color:#f69321; }
.leftMenu a:hover i{color:#510808; }
.leftMenu a:last-child{border-bottom:none; }
.borderLeftWidth{border-left-width:10px; }
/*--.dashboardBox :after{ position:absolute; content:""; left:0px; top:0px; border:solid 20px; border-color:#f1f1f1 transparent transparent #f1f1f1;}--*/
.minHeight{min-height:705px; }

#home{background:url("https://www.instaglobaltravel.com/assets/images/Fnlbanner-bg2.jpg") 0px 0px/100% auto fixed no-repeat #333; width:100%; height:100%; /*min-height:450px;*/ z-index:10; position:relative; border-bottom:dashed 1px #fff; }
#home:after{position:absolute; top:0; left:0; width:100%; height:100%; content:""; background:rgba(0,0,0,0.4); z-index:1; }
#home .shadow-overlay{position:absolute; top:0; left:0; width:100%; height:100%; opacity:.9; background:-moz-linear-gradient(left, black 0%, black 20%, transparent 100%); background:-webkit-linear-gradient(left, black 0%, black 20%, transparent 100%); background:linear-gradient(to right, black 0%, black 20%, transparent 100%); filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=1); }
#home .row{max-width:1290px; }
#home.home-static{background-repeat:no-repeat; background-position:center; -webkit-background-size:cover; -moz-background-size:cover; background-size:cover; }
/*#home.home-particles{overflow:hidden; }*/
#home.home-particles .pg-canvas {position:absolute; top:0; left:0; height:100%; width:100%; opacity:0.2; }
#home.home-slides{height:100%!important; }
.search-banner{background-image:url("https://www.instaglobaltravel.com/uploads/destination_images/united-arab-emirates-uae.jpg"); }
.tab-panel{padding:0px; margin:0px; width:100%; }
.tabs-menu {float:left; width:30%; margin:0px; padding:0px; list-style:none; }
.tabs-menu li {line-height:40px; float:left; color:#08376a; width:100%; box-sizing:border-box; }
.tabs-menu li.current {position:relative; z-index:5; border-right:solid 1px #fff!important; border:solid #cdd6de; border-width:1px 0px 1px 1px; margin-left:1px; }
.tabs-menu li a {padding:10px 10px; color:#333; text-decoration:none; font-size:16px; width:100%; text-align:left; border-bottom:solid 1px #d7d7d7; background:#f4f4f4; border-left:solid 1px #e6e6e6; margin-bottom:2px; }
.tabs-menu .current a {color:#000; background:#fffbf6; border-left:solid 5px #23424b; }
.tab {border:1px solid #cdd6de; background:#fff; width:70%; }
.tab-content {width:100%; padding:20px 20px; display:none; min-height:300px; background:#f9f9f9; }
.tab-content p{line-height:22px; margin:0px 0px 20px 0px; padding:0px; width:100%; text-align:justify; }
.tab-content ul{margin:0px 0px 20px 2%; padding:0px; width:98%; }
.tab-content ul li{margin:0px; padding:0px; width:100%; text-align:left; }
.tabs-menu li a.active, .tabs-menu li a:hover{background-color:#23424b !important; color:#ffffff; }
/*.tabs-menu li a.active:after, .tabs-menu li:hover a:after{content:""; border-left:14px solid #5c3d3d; border-top:14px solid transparent; border-bottom:14px solid transparent; position:absolute; left:100%; top:16px; }*/
.tabs-menu li{position:relative; z-index:1; }
.tabs-menu .active:after, .tabs-menu li:hover > a:after{content:""; border-left:14px solid #23424b; border-top:14px solid transparent; border-bottom:14px solid transparent; position:absolute; left:100%; top:16px; }

#tab-1 {display:block; }
.lineheight16{line-height:16px; }
.displayNone{display:none; }
.height380 {min-height:190px!important; }
.floatLeft{float:left; }
.floatRight{float:right; }
.facebook, .twitter, .linkedin, .youtube, .instagram{background:#5c3d3d; }
.facebook:hover{background-color:#527EBF; }
.twitter:hover{background-color:#25C0E2; }
.linkedin:hover{background-color:#147BB7; }
.youtube:hover{background-color:#ff0000; }
.instagram:hover{background-color:#dd2a7b; }
.select2-container .select2-choice > .select2-chosen{width:80% !important; background:#e9e9e9; z-index:1; position:relative; margin-left:-5px; }
.points{}
.points ul, .points ol {list-style:outside; width:98%; margin:0px 0px 15px 2%; }
.points ul li, .points ol li {width:100%; padding:2px; box-sizing:border-box; line-height:20px; }
.why-icon{width:70px; }
.ac-container article p a{float:none; color:#b15300; }
.forgotH{min-height:410px; }
.forgotH1{min-height:440px; }

.angular-editor-toolbar{width:100% !important; }
.angular-editor .angular-editor-wrapper{width:100% !important; }
.angular-editor-textarea{width:100% !important; margin-bottom:10px; min-height:200px !important; }
.post-text p{width:100%; margin:0px 0px 20px; text-align:justify; line-height:20px; }
.post-text h1{width:100%; margin:0px 0px 10px; font-size:20px; color:#5c3d3d; padding:0px 0px 5px; border-bottom:solid 1px #ddd; }
.post-text h2{width:100%; margin:0px 0px 10px; font-size:16px; color:#5c3d3d; padding:0px 0px 5px; border-bottom:solid 1px #ddd; }
.post-text a{float:none; color:#f69321; }
.md-form.md-outline input[type="text"]{background-color:#fff !important; }
.md-form{width:100%; }
.ng-select div{float:none; }
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{float:none !important; }
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {background-color:#5c3d3d !important; color:#fff !important; }
.ng-dropdown-panel .ng-dropdown-panel-items {height:150px; max-height:150px!important; }
.ng-select .ng-arrow-wrapper {width:40px !important; background:#862b3a; height:42px; }
.ng-select.ng-select-opened > .ng-select-container .ng-arrow {top:6px !important; border-color:transparent transparent #fff !important; float:none !important; }
.ng-select .ng-arrow-wrapper .ng-arrow {border-color:#fff transparent transparent !important; float:none !important; top:10px; left:2px; }
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{border-color:#eee !important; }
.ng-select.ng-select-single .ng-select-container {height:42px !important; }
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{top:12px !important; }
.ng-select .ng-select-container{border:1px solid #dadce0 !important; }
.ng-select .ng-select-container .ng-value-container .ng-input > input{background:none !important; }
.ng-select .ng-clear-wrapper .ng-clear {display:none !important; }
  
/*calender*/
.picker__box .picker__header {float:none !important; }
.picker__box .picker__header .picker__date-display{float:none !important; }
.picker__box .picker__footer {float:none; }
.picker__box .picker__table td.picker__day div.picker__day{padding:.75rem !important; }
.picker__frame {min-height:480px !important; }
.picker__date-display{margin-bottom:10px; }
.md-form .mydp-date{box-sizing:border-box !important; background-color:initial !important; border:1px solid #dadce0 !important; border-radius:4px !important; outline:none !important; box-shadow:none !important; transition:all .3s !important; padding-bottom:11px !important; font-size:14px !important; }
.md-form .form-control mydp-date {padding:1rem .75rem !important; }
.md-form {position:relative; }
.md-form label {position:absolute; top:-4px; left:0; padding-left:10px; font-size:14px; color:#757575; cursor:text; transition:transform .2s ease-out, color .2s ease-out; transform:translateY(9px); transform-origin:0% 100%; line-height:24px; }
.md-form label.active {left:8px; padding-right:5px; padding-left:5px; font-weight:500; background:#fff; transform:translateY(-13px) scale(0.8); }
.mydp label.active{font-size:.7rem !important;}
.strikeThrough{ float:none; color:#f00; text-decoration:line-through;}
ul.naviMenu{ float:left;}
ul.naviMenu li{ float:left; position:relative; list-style:none;}
ul.naviMenu li a{ float:left; padding:10px; text-decoration:none; color:#6c6c6c; font-family:basicFont; font-size:14px; margin-right:5px;}
ul.naviMenu li a:hover{ color:#fff;background:#f69321; border-radius:30px;}
ul.naviMenu li ul{ display:none; padding:0px; }
ul.naviMenu li:hover ul{ display: block; position: absolute; top: 60px; left: 0px; background: #fff; margin: 0px; padding: 20px 0px; width: 300px; transition: all 0.5s ease; border-radius: 24px; box-shadow: 0px 0px 3px #eee;}
ul.naviMenu li:hover ul li{ width:100%; float:left; margin:0px; padding:0px; border-radius:0px;}
ul.naviMenu li:hover ul li a{float:left; width:100%; text-decoration:none; border-radius:0px; background:#fff; text-decoration:none; margin:0px; padding:15px 20px!important; color:#000;}
ul.naviMenu li:hover ul li a:hover{ background:#f69321; color:#fff;}
ul.naviMenu li:hover ul li a i{ color:#ffbb6c; padding-right:10px;}
.chat img{ width: 170px!important;}

.alertBoxToaser{ position:fixed; top:10px; right:10px; padding:15px; width:300px; border-radius:5px; box-shadow:0 0 12px #999; color:#fff!important; z-index:1000; font-weight:bold;}
.alertBoxToaser.success{ background:#51A351!important;}
.alertBoxToaser.error{ background:#BD362F!important;}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
	white-space:initial !important;
}

.inbox-open div, .inbox-open p, .inbox-open span, .inbox-open ul, .inbox-open li, .inbox-open a{ float:none; box-sizing:content-box;}
div.inbox-open div:root{ position:absolute; left:0px; right:0px;}
.arial{	font-family:Arial, Helvetica, sans-serif !important;}
.fa-history:before{content:"\f1da"}.fa-circle-thin:before{content:"\f1db"}.fa-header:before{content:"\f1dc"}

.addMoney{ position:absolute; right:10px; top:10px; background:#f00; border-radius:20px; color:#fff; padding:4px 10px; font-size:12px; }
.addMoney i{ color:#fff!important; padding:0px!important; margin:0px!important;}
.addedMoney { color:#f00!important; padding:0px!important; margin:0px!important; font-size:16px;}

.documentList li a{float:none!important;}

/*.testi {
    background: rgba(0,0,0, 0.5);
    border: 1px solid #484848;
    border-radius: 10px;
    padding: 20px;
    min-height: 180px;
    margin-bottom: 10px;
}
blockquote {
    font-weight: 100;
    font-size: 1rem;
    max-width: 600px;
    line-height: 1.4;
    position: relative;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
    padding: 0.3rem 0.3rem 0.3rem 1rem;
}
cite {
    line-height: 3;
    text-align: left;
    margin-left: 12px;
    color: rgba(255, 255, 255, 0.7);
}
blockquote:before {
    content: '“';
    left: -0.9rem;
    top: -0.9rem;
}
blockquote:before, blockquote:after {
    position: absolute;
    color: rgba(169, 169, 169, 0.3);
    font-size: 3rem;
    width: 4rem;
    height: 4rem;
}
blockquote:after {
    content: '”';
    right: -3rem;
    bottom: -5rem;
}
*/
.testi {
border-bottom: 2px solid #f69321;
  padding: 20px;
  min-height: 180px;
  margin: 0px 1% 10px;
  width: 31.33%;
  border-top: 2px solid #ddd;
  background: #fbfbfb;
}
blockquote {
    font-weight: 100;
    font-size: 14px;
    max-width: 600px;
    line-height: 22px;
    position: relative;
    margin: 0;
    padding: 0.3rem 0.3rem 0.3rem 1rem;
}
cite {
line-height: 3;
  text-align: left;
  margin-left: 12px;
  background: #2cbd47;
  padding: 2px 5px;
  color: #fff;
  border-radius: 3px;
}

/*blockquote:before {
    content: '“';
    left: -0.9rem;
    top: -0.9rem;
}
blockquote:before, blockquote:after {
    position: absolute;
    color: #5c3d3d;
    font-size: 3rem;
    width: 4rem;
    height: 4rem;
}
blockquote:after {
    content: '”';
    right: -3rem;
    bottom: -5rem;
}*/

.breadcrumb span.sep { display:none;}
.requireddoc .row.font16.brown.titleFont.marginBottom.label{ color:#fff!important;}


.title{color: #510808; font-family: titleFont; position: relative; font-size:36px; line-height:42px; margin:0 auto 25px; float:none; display:table;}
.title:before { content: ""; position: absolute; left: 50%; top: 120%; width: 50%; background: #F69321; height: 2px; transform: translate(-50%,-50%);}
.shadow-right{ box-shadow:7px 0px 3px #ddd;}

.budgetFrndPoints{ width:100%;}
.budgetFrndPoints ul{ list-style:inside; width:100%; margin:0px; padding:0px 0px;}
.budgetFrndPoints ul li{ width:100%; margin:0px; padding:12px 30px; text-align:left; font-size:14px; line-height:22px; border-bottom:solid 1px #ccc; background:#fbfbfb;}
.budgetFrndPoints ul li:last-child{ border-bottom:none;}
.flexWrap{ display:flex; flex-wrap:wrap;}
.flex{ display:flex;}
.travelBox{ width:24%; margin:0px 0.5%; border:solid 1px #ddd; border-bottom:solid 4px #f69321; background:#fff;}
/*.travelTitle{position: absolute;left: 0px;top: -42px;background: rgba(0,0,0,0.8); font-family:titleFont; justify-content: center;padding: 5px 0px; font-size:20px; width:100%; display:flex; line-height:32px; color:#f69321;}*/
.travelBox img{ min-height:170px; transition:1s ease;}
.travelBox img:hover{ transform:scale(1.2); transition:0.5s ease; }

.imagesBox{ position:relative;}
.travelTitleBoxWrap{ position:absolute; left:0px; right:0px; top:0px; bottom:0px; width:100%; height:100%; background:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 60%); transition:0.5s ease;}
.travelTitleBox{position: absolute;left: 0px;top:80px;/*background: rgba(0,0,0,0.8);*/ padding: 7px 10px; width:100%; height:100%; transition:1s ease;}
.imagesBox:hover .travelTitleBox{ top:0px; transition:0.5s ease;}
.imagesBox:hover .travelTitleBoxWrap{ top:0px; transition:0.5s ease; background:#510808; height:100%; width:100%;}
.travelTitleBox span{ font-family:titleFont; font-size:20px; color:#f69321; line-height:32px;}
.travelTitleBox p{ color:#fff; font-size:11px;}

.overflowHidden{ overflow:hidden; }
.orangeLine{ border-left:solid 5px #f69321;}



/*.testimonial-overflow { overflow:hidden;}
.testimonial-main {overflow: auto;align-content: flex-start;justify-content: flex-start;display: flex;width: fit-content;}
.testimonial-section {background:#ffd85a url(https://www.travelpaths.org/tourism/images/testimonial-bg.png) center center no-repeat; padding:40px 0;background-size: cover;}
.testimonial-col {width:350px;float: left;background: #fff;padding: 20px;border-radius: 15px; margin-right:20px;white-space: nowrap;overflow: hidden;position: relative;}
.test-star {background: #01b77c;color: #fff;padding: 5px 10px;border-radius: 25px; margin-bottom:15px;}
.test-star a {color: #fff;}
.test-date {color: #c1c1c1;}
.test-text {}
.test-text h4 {font-weight: 500;}
.test-text p { white-space:normal;}*/

.sliderbuttons {width: 100%;float: left;position: absolute;transform: translate(-50%,-50%); left: 50%;top:50%;}
.sliderbuttons li.prev {left: -55px; position: relative; list-style: none; border-radius: 100%;background: url(https://www.travelpaths.org/tourism/images/arrowprevwhite.png) no-repeat; background-position: left center;background-size: contain;width:45px;height:45px; cursor:pointer;background-position: center;background-color: #fff !important;background-size: 8px !important;box-shadow: 2px 2px 15px -8px rgba(0,0,0,0.9);}
.sliderbuttons li.next {right: -55px;position: relative;list-style: none;border-radius: 100%;float: right;background: url(https://www.travelpaths.org/tourism/images/arrownextwhite.png) no-repeat;width: 45px;height: 45px;cursor: pointer;background-position: center;background-color: #fff!important;background-size: 8px !important;box-shadow: 2px 2px 15px -8px rgba(0,0,0,0.9);}

/* slides */
#example{text-align:center;}
.slideshow{}
.slideshow {position:relative; margin:0 0 1em; direction:ltr;}
.slideshow a {font-size:.75em; line-height:2em; text-decoration:none; color:#fff; background:#666;}
.slideshow a:hover,
.slideshow .selected a {background-color:#333;}
.carousel {margin:0; padding:0; list-style:none;}
.slide {z-index:100;}
.slide img {display:block;}
/* skip */
.slides-next,
.slides-prev {position:absolute; top:50%; margin-top:-1em; padding:0 .5em; z-index:120;}
.slides-next {right:0;}
.slides-prev {left:0;}
.slides-next.disabled,
.slides-prev.disabled {opacity:.5; cursor:auto;}

.overflowHidden{ overflow:hidden;}
.igt-wel-bg{background:url(https://dev.instaglobaltravel.com/assets/images/igt-wel-bg.jpg) 0 0/100% auto fixed no-repeat;}

.content-inner ul{ list-style:outside;}
.content-inner ul li{ width:98%; margin:0px 0px 0px 2%;}
.content-inner h2{width:100%; margin:0px 0px 10px; padding:0px 0px 10px; font-size:22px; color:#1c88c8; border-bottom:solid 1px #ddd; font-weight:normal;}
.content-inner h3{width:100%; margin:0px 0px 10px; padding:0px; font-size:18px; color:#5c3c3d; font-weight:normal;}
.content-inner p { margin: 0px 0px 20px;}
.content-inner h1, .content-inner h4, .content-inner h5, .content-inner h6{width:100%; margin:0px 0px 10px; padding:0px; color:#000; font-weight:normal;}

.ac-container input:checked ~ article.ac-medium a { float:none; color:#000;}
.ac-container input:checked ~ article.ac-medium .points ul li a { float:none; color:#000;}